import { lazy } from 'react';

const CertifiedAvailableMarketerPageConfig = {
	settings: {
		layout: {
			config: {
				footer: {
					display: false
				},
			}
		}
		
	},
	routes: [
		{
			path: '/certificates/marketer/avaibles',
			component: lazy(() => import('./CertifiedAvailablePage'))
		}
	]
};

export default CertifiedAvailableMarketerPageConfig;