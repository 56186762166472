import { lazy } from 'react';

const changePasswordConfig = {
	settings: {
		layout: {
			config: {
				footer: {
					display: true,
					style: 'static'
				},
			}
		}
		
	},
	routes: [
		{
			path: '/auth/change-password',
			component: lazy(() => import('./changePassword'))
		}
	]
};

export default changePasswordConfig;
