import { GET, POST } from '..'


export const getDeliveredMaterialInfoDetails = (id) => {
  return GET(`/api/transformers/delivered-material-info/${id}`)
}

export const updateDeliveredMaterialValidationStatus = (data) => {
  return POST('/api/transformers/delivered-material-validation', data)
}

export const getTransformerCertificationInfo = (id) => {
  return GET(`/api/transformers/certification-info/${id}`)
}
