import { POST } from '..'

export const loginUser = (data) => {
  return POST('/api/user/login', data)
}

export const passRecoveryUser = (data) => {
  return POST('/api/user/password-recovery', data)
}

export const saveChangePassword = (data) => {
  return POST('/api/user/change-password', data)
}


