const verifierNavigation = [
    {
        id: 'verifierCertificates',
        title: 'Certificados',
        type: 'group',
        icon: 'verified_user',
        children: [
            {
                id: 'toBeChecked',
                title: 'Por verificar',
                type: 'item',
                url: '/verificator-certified',
                icon: 'lock'
            },
            {
				id: 'verified-certificate-avaibles',
				title: 'Disponibles',
				type: 'item',
				icon: 'history',
				url: '/certificates/verified/avaibles',
			}
        ]
    }, 
    {
        id: 'associatedTransformers',
        title: 'Transformadores asociados',
        type: 'group',
        icon: 'verified_user',
        children: [
            {
                id: 'consult',
                title: 'Consultar',
                type: 'item',
                url: '/verifier/consult/associated-transformers',
                icon: 'lock'
            }
        ]
    },
    {
		id: 'verifier-consults',
		title: 'Consultas',
		type: 'group',
		icon: 'account_tree',
		children: [
			{
				id: 'verifier-certification-info',
				title: 'Certificados aprobados',
				type: 'item',
				icon: 'history',
				url: '/verifier/consult/aprroved-certification',
			},
            {
				id: 'verifier-rejected-certification-info',
				title: 'Certificados rechazados',
				type: 'item',
				icon: 'history',
				url: '/verifier/consult/rejected-certification',
			},
            {
				id: 'verifier-received-transfer-info',
				title: 'Certificados recibidos',
				type: 'item',
				icon: 'history',
				url: '/verifier/consult/received-transfer',
			},
            {
				id: 'verifier-send-transfer-info',
				title: 'Certificados transferidos',
				type: 'item',
				icon: 'history',
				url: '/verifier/consult/send-transfer',
			}
		]
	},
];



export default verifierNavigation;
