const transformerNavigation = [
	{
		id: 'register-material-transformer',
		title: 'Registro de material',
		type: 'group',
		icon: 'account_tree',
		children: [
			{
				id: 'delivery-material',
				title: 'Material Entregado',
				type: 'collapse',
				icon: 'play_arrow',
				children: [
					{
						id: 'validation-material',
						title: 'Validar entrega',
						type: 'item',
						url: '/transformer/validation-material'
					},
					{
						id: 'register-material-delivery',
						title: 'Registrar entrega',
						type: 'item',
						url: '/transformer/register-material-delivery'
					},
				]
			},
			{
				id: 'factured-material',
				title: 'Registrar Factura',
				type: 'item',
				icon: 'history',
				url: '/transformer/factured',
			},
			{
				id: 'transformed-material',
				title: 'Registrar Transformación',
				type: 'item',
				icon: 'history',
				url: '/transformer/transformed-material',
			},
			{
				id: 'certified-material',
				title: 'Certificar transformación',
				type: 'item',
				icon: 'history',
				url: '/transformer/certified-material',
			},
		]
	},
	{
		id: 'certificates',
		title: 'Certificados',
		type: 'group',
		icon: 'account_tree',
		children: [
			
			{
				id: 'certificate-verification',
				title: 'En verificación',
				type: 'item',
				icon: 'history',
				url: '/certificates/verification',
			},
			{
				id: 'certificate-avaibles',
				title: 'Disponibles',
				type: 'item',
				icon: 'history',
				url: '/certificates/avaibles',
			},
			{
				id: 'transformer-certificate-nominees',
				title: 'Nominados',
				type: 'item',
				icon: 'history',
				url: '/certificates/transformer/nominees',
			}
		]
	},
	{
		id: 'consults',
		title: 'Consultas',
		type: 'group',
		icon: 'account_tree',
		children: [
			{
                id: 'deliveredMaterial',
                title: 'Entrega de material',
                type: 'item',
                url: '/transformer/consult/delivered-material',
                icon: 'lock'
            },
            {
                id: 'invoicedMaterial',
                title: 'Facturación de material',
                type: 'item',
                url: '/transformer/consult/material-invoiced',
                icon: 'lock'
            },
            {
                id: 'transformedMaterial',
                title: 'Transformación de material',
                type: 'item',
                url: '/transformer/consult/material-transformed',
                icon: 'lock'
            },
            {
                id: 'pendingCertification',
                title: 'Certificados solicitados para verificación',
                type: 'item',
                url: '/transformer/consult/pending-certification',
                icon: 'lock'
            },
			{
                id: 'rejectedCertification',
                title: 'Certificados rechazados',
                type: 'item',
                url: '/transformer/consult/rejected-certification',
                icon: 'lock'
            },
			{
                id: 'aprrovedCertification',
                title: 'Certificados aprobados',
                type: 'item',
                url: '/transformer/consult/aprroved-certification',
                icon: 'lock'
            },
			{
                id: 'sendTransfer',
                title: 'Certificados transferidos',
                type: 'item',
                url: '/transformer/consult/send-transfer',
                icon: 'lock'
            },
			{
                id: 'receivedTransfer',
                title: 'Certificados recibidos',
                type: 'item',
                url: '/transformer/consult/received-transfer',
                icon: 'lock'
            },
		]
	},
	{
		id: 'configuration',
		title: 'Configuración',
		type: 'group',
		icon: 'account_tree',
		children: [
			
			{
				id: 'technique',
				title: 'Técnica',
				type: 'item',
				icon: 'history',
				url: '/auth/my-profile',
			},
			{
				id: 'count-user',
				title: 'Cuenta de usuario',
				type: 'item',
				icon: 'history',
				url: '/transformer/configuration/count/user',
			}
		]
	},
];

export default transformerNavigation;



