import { getUserToken, removeUserToken } from '../../helpers/sessionValidation'
// TODO: Add security to this module

function validateToken(response) {
  if (response.status === 401) {
    // Token invalido, se borra el token del localStorage
    removeUserToken()
    // Se redireje la pagina al login
    window.location.href = '/login'
    return
  }
  return response
}

function getOptions(method, data = null) {
  window.localStorage.setItem('i18nextLng', 'es');

  const lng = window.localStorage.getItem('i18nextLng')
    ? window.localStorage.getItem('i18nextLng').split('-')[0]
    : 'es';

  const options = {
    method,
    ...(data && { body: JSON.stringify(data) }),
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: `Bearer ${getUserToken()}`,
      platform: 'ecorep',
      lng: lng || 'es'
    }
  }
  return options
}

function getOptionsForm(method, data = null) {
  const options = {
    method,
    ...(data && { body: data })
  }
  return options
}

const request = async (url, options) => {

  /*  const urlEnd = `${process.env.REACT_APP_API}${url}` */

  const baseURL = process.env.REACT_APP_BACKEND_URL

  url = baseURL + url
  try {
    const httpResponse = await fetch(url, options)
    if (validateToken(httpResponse)) {
      const r = await httpResponse.json()
      return {
        be: 'ok',
        data: r
      }
    }
    return undefined
  } catch (error) {
    return {
      be: 'nok',
      msg: error.message
    }
  }
}

const requestForm = async (url, options) => {
  const baseUrl = process.env.REACT_APP_BACKEND_URL
  url = baseUrl + url
  try {
    const httpResponse = await fetch(url, options)
    if (validateToken(httpResponse)) {
      const r = await httpResponse.json()
      return {
        be: 'ok',
        data: r
      }
    }
    return undefined
  } catch (error) {
    return {
      be: 'nok',
      msg: error.message
    }
  }
}

export const GET = (url) => {
  const options = getOptions('GET')
  return request(url, options)
}

export const POST = (url, data) => {
  const options = getOptions('POST', data)
  return request(url, options)
}

export const POSTForm = (url, data) => {
  const options = getOptionsForm('POST', data)
  return requestForm(url, options)
}
