import { StyledVerificationsCertificates } from "./StyledVerificationsCertificates";
import { Button, Container, Dialog, DialogContent, FormControl, Grid, Paper, Table, TableBody, TableCell, TableContainer, TableFooter, TableHead, TableRow, TextField } from '@material-ui/core';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { motion } from 'framer-motion';
import clsx from 'clsx';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Loader from "app/common/Loader";
import Typography from '@material-ui/core/Typography';
import { Controller, useForm } from 'react-hook-form';
import { useEffect, useState } from "react";
import LoadSuccess from "../../components/load-success/LoadSuccess";
import { getTransformerCertificationInfo } from "app/services/api/transformer/validations";
import TablePagination from '@material-ui/core/TablePagination';
import { getCertificationInfo } from "app/services/api/public/validations";

const VerificationsCertificates = props => {
    const [loading, setLoading] = useState(false);
    const [showModalSuccess, setShowModalSuccess] = useState(false);
    const [filter, setFilter] = useState('');
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [data, setData] = useState({
        cols: [],
        content: []
    });
    const [filteredContent, setFilteredContent] = useState([]);
    const [searched, setSearched] = useState(false);

    const loadContent = async () => {
        const { be, data } = await getCertificationInfo();
        if (be !== 'ok') {
            // DO SOMETHING IF WRONG
            return;
        }

        setData({
            cols: [
                { id: 'userNameCompanyInfoName', label: 'Titular', minWidth: 120 },
                { id: 'quantity', label: 'Cantidad (ton)', aling: 'center', minWidth: 180 },
                { id: 'material', label: 'Material', aling: 'center', minWidth: 180 },
                { id: 'transformerName', label: 'Transformador', aling: 'center', minWidth: 180 },
                { id: 'transformationYear', label: 'Año de transformación', aling: 'center', minWidth: 180 },
                { id: 'certificate', label: 'Certificado', minWidth: 120 }
            ],
            content: data.certificationInfo
        });
        setLoading(false);
    };

    const filterRows = () => {
        const filterText = filter.trim().toUpperCase();
        if (filterText === '') return data.content;

        return data.content.filter(row => {
            const value = row['certificate'];
            if (value !== undefined && value !== null) {
                const textValue = String(value).toUpperCase();
                return textValue.includes(filterText);
            }
            return false;
        });
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const handleFilterChange = (event) => {
        setFilter(event.target.value.toUpperCase());
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleSearch = () => {
        setFilteredContent(filterRows());
        setSearched(true);
    };

    useEffect(() => {
        setLoading(true);
        loadContent();
    }, []);

    return (
        <>
            <Loader visible={loading} />
            <Dialog open={showModalSuccess} centered>
                <DialogContent>
                    <LoadSuccess message="" />
                </DialogContent>
            </Dialog>
            <StyledVerificationsCertificates>
                <div className="section7" style={{ marginTop: '0px' }}>
                    <div style={{ textAlign: 'center' }}>
                        <br />
                        <h1 style={{ color: '#fff' }}>
                            Validación de certificados <br />
                        </h1>
                    </div>
                </div>
            </StyledVerificationsCertificates>
            <div className={clsx('flex-col flex-auto items-center justify-center p-16 sm:p-32')}>
                <div className="flex-col items-center justify-center w-fullWidth">
                    <motion.div initial={{ opacity: 0, scale: 0.6 }} animate={{ opacity: 1, scale: 1 }}>
                        <Container>
                            <Grid container justify="center" alignItems="center" spacing={2}>
                                <Grid item md={6}>
                                    <TextField
                                        className="mb-16"
                                        type="name"
                                        fullWidth
                                        placeholder="Debes buscar por número del certificado"
                                        value={filter}
                                        onChange={handleFilterChange}
                                    />
                                </Grid>
                                <Grid item md={2}>
                                    <Button color="primary" variant="contained" onClick={handleSearch}>
                                        Buscar
                                    </Button>
                                </Grid>
                            </Grid>
                        </Container>
                        <br />
                        <TableContainer>
                            <Table stickyHeader aria-label="sticky table">
                                <TableHead>
                                    <TableRow>
                                        {data.cols.map((column) => (
                                            <TableCell
                                                key={column.id}
                                                style={{ minWidth: column.minWidth, textAlign: column.align, backgroundColor: "rgb(190 191 194)" }}
                                            >
                                                {column.label}
                                            </TableCell>
                                        ))}
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {!searched ? (
                                        <TableRow>
                                            <TableCell colSpan={data.cols.length} align="center">
                                                <strong>Debes buscar por número del certificado</strong>
                                            </TableCell>
                                        </TableRow>
                                    ) : filteredContent.length === 0 ? (
                                        <TableRow>
                                            <TableCell colSpan={data.cols.length} align="center">
                                                <strong>Certificado no encontrado</strong>
                                            </TableCell>    
                                        </TableRow>
                                    ) : (
                                        filteredContent.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => (
                                            <TableRow hover role="checkbox" tabIndex={-1} key={row.code}>
                                                {data.cols.map((column) => {
                                                    const value = row[column.id];
                                                    return (
                                                        <TableCell key={column.id} align={column.align} minWidth={column.minWidth}>
                                                            {column.format && typeof value === 'number' ? column.format(value) : value}
                                                        </TableCell>
                                                    );
                                                })}
                                            </TableRow>
                                        ))
                                    )}
                                </TableBody>
                            </Table>
                        </TableContainer>
                        <TablePagination
                            rowsPerPageOptions={[10, 25, 100]}
                            component="div"
                            count={filteredContent.length}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            onChangePage={handleChangePage}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                        />
                    </motion.div>
                </div>
            </div>
        </>
    );
};

export default VerificationsCertificates;