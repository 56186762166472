import { lazy } from 'react';

const CertifiedNomineesTransformerPageConfig = {
	settings: {
		layout: {
			config: {
				footer: {
					display: false
				},
			}
		}
		
	},
	routes: [
		{
			path: '/certificates/transformer/nominees',
			component: lazy(() => import('./CertifiedNomineesPage'))
		}
	]
};

export default CertifiedNomineesTransformerPageConfig;