import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import Icon from '@material-ui/core/Icon';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import MenuItem from '@material-ui/core/MenuItem';
import Popover from '@material-ui/core/Popover';
import Typography from '@material-ui/core/Typography';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import jwtService from 'app/services/jwtService';

function UserMenu(props) {
	const dispatch = useDispatch();
	const user = useSelector(({ auth }) => auth.user);

	const [userMenu, setUserMenu] = useState(null);

	const userMenuClick = event => {
		setUserMenu(event.currentTarget);
	};

	const userMenuClose = () => {
		setUserMenu(null);
	};
	
	return (
		<>
			<Button className="min-h-40 min-w-40 px-0 md:px-16 py-0 md:py-6" onClick={userMenuClick}>
				<div className="hidden md:flex flex-col mx-4 items-end">
					<Typography component="span" className="font-semibold flex">
						{user.data.displayName}
					</Typography>
					<Typography className="text-11 font-medium capitalize" color="textSecondary">
						{user.data.nameCompany.toString()}
						<br></br>
						{user.data.companyRole.toString()}
						{/* 				{(!user.data.nameCompany || (Array.isArray(user.data.nameCompany) && user.data.nameCompany.length === 0)) && 'Guest'} */}
					</Typography>
				</div>

				{user.data.photoURL ? (
					<Avatar className="md:mx-4" alt="user photo" src={user.data.photoURL} />
				) : (
					<Avatar className="md:mx-4">{user.data.displayName[0]}</Avatar>
				)}
			</Button>

			<Popover
				open={Boolean(userMenu)}
				anchorEl={userMenu}
				onClose={userMenuClose}
				anchorOrigin={{
					vertical: 'bottom',
					horizontal: 'center'
				}}
				transformOrigin={{
					vertical: 'top',
					horizontal: 'center'
				}}
				classes={{
					paper: 'py-8'
				}}
			>
				{/* {!user.role || user.role.length === 0 ? (
					<>
						<MenuItem component={Link} to="/login" role="button">
							<ListItemIcon className="min-w-40">
								<Icon>lock</Icon>
							</ListItemIcon>
							<ListItemText primary="Login" />
						</MenuItem>
						<MenuItem component={Link} to="/register" role="button">
							<ListItemIcon className="min-w-40">
								<Icon>person_add</Icon>
							</ListItemIcon>
							<ListItemText primary="Register" />
						</MenuItem>
					</>
				) : (
					<> */}
				<MenuItem component={Link} to="/auth/my-profile" onClick={userMenuClose} role="button">
					<ListItemIcon className="min-w-40">
						<Icon>account_circle</Icon>
					</ListItemIcon>
					<ListItemText primary="Mi perfil" />
				</MenuItem>
				<MenuItem component={Link} to="/auth/change-password" onClick={userMenuClose} role="button">
					<ListItemIcon className="min-w-40">
						<Icon>vpn_key</Icon>
					</ListItemIcon>
					<ListItemText primary="Cambiar contraseña" />
				</MenuItem>
				<MenuItem
					onClick={() => {
						jwtService.logout()
					}}
				>
					<ListItemIcon className="min-w-40">
						<Icon>exit_to_app</Icon>
					</ListItemIcon>
					<ListItemText primary="Cerrar sesion" />
				</MenuItem>
				{/* </>
				)} */}
			</Popover>
		</>
	);
}

export default UserMenu;
