import { lazy } from 'react';

const RegisterEditPageConfig = {
	settings: {
		layout: {
			config: {
				footer: {
					display: true,
					style: 'static'
				},
			}
		}
		
	},
	routes: [
		{
			path: '/auth/edit-register/:companyId',
			component: lazy(() => import('./RegisterEditPage'))
		}
	]
};

export default RegisterEditPageConfig;
