import { Redirect } from 'react-router-dom';
import FuseUtils from '@fuse/utils';
import pagesConfigs from 'app/main/pages/pagesConfigs';
import HomeConfig from '../main/Home/HomeConfig'
import ContactConfig from 'app/main/Contact/ContactConfig';
import VerificationsCertificatesConfig from 'app/main/Certifications/Verifications/VerificationsCertificatesConfig';

const routeConfigs = [
	...pagesConfigs,
	HomeConfig,
	ContactConfig,
	VerificationsCertificatesConfig
];

const routes = [
	// if you want to make whole app auth protected by default change defaultAuth for example:
	// ...FuseUtils.generateRoutesFromConfigs(routeConfigs, ['admin','staff','user']),
	// The individual route configs which has auth option won't be overridden.
	...FuseUtils.generateRoutesFromConfigs(routeConfigs),
	{
		path: '/',
		component: () => <Redirect to="/home" />
	},
];

export default routes;
