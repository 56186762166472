import { lazy } from 'react';

const CertifiedNomineesMarketerPageConfig = {
	settings: {
		layout: {
			config: {
				footer: {
					display: false
				},
			}
		}
		
	},
	routes: [
		{
			path: '/certificates/marketer/nominees',
			component: lazy(() => import('./CertifiedNomineesPage'))
		}
	]
};

export default CertifiedNomineesMarketerPageConfig;