const marketerNavigation = [
	{
		id: 'marketer-certificates',
		title: 'Certificados',
		type: 'group',
		icon: 'account_tree',
		children: [
			{
				id: 'marketer-certificate-avaibles',
				title: 'Disponibles',
				type: 'item',
				icon: 'history',
				url: '/certificates/marketer/avaibles',
			},
			{
				id: 'marketer-certificate-nominees',
				title: 'Nominados',
				type: 'item',
				icon: 'history',
				url: '/certificates/marketer/nominees',
			}
		]
	},
    {
		id: 'marketer-consults',
		title: 'Consultas',
		type: 'group',
		icon: 'account_tree',
		children: [
			{
				id: 'marketer-received-transfer-info',
				title: 'Certificados recibidos',
				type: 'item',
				icon: 'history',
				url: '/marketer/consult/received-transfer-info',
			},
            {
				id: 'marketer-transfer',
				title: 'Certificados disponibles',
				type: 'item',
				icon: 'history',
				url: '/marketer/consult/transfer',
			},
            {
				id: 'marketer-send-transfer-info',
				title: 'Certificados transferidos',
				type: 'item',
				icon: 'history',
				url: '/marketer/consult/send-transfer-info',
			},
            {
				id: 'marketer-nomination-info',
				title: 'Certificados nominados',
				type: 'item',
				icon: 'history',
				url: '/marketer/consult/nomination-info',
			}
		]
	},
];

export default marketerNavigation;
