import { lazy } from 'react';

const SendMarketerTransformerTransferConfig = {
	settings: {
		layout: {
			config: {
				footer: {
					display: false
				},
			}
		}
		
	},
	routes: [
		{
			path: '/marketer/consult/send-transfer-info',
			component: lazy(() => import('./SendTransferContainer'))
		}
	]
};

export default SendMarketerTransformerTransferConfig;


